export const COLORS = {
  purple: "#8D5494",
  violet: "#584774",
  blurple: "#6A5FC1",
  pink: "#E1567C",
  flame: "#F4834F",
  gold: "#F1B71C",
  "light-purple": "#AD6CAA",
  "light-violet": "#79628C",
  "light-pink": "#FA7FAA",
  "light-flame": "#FFB287",
  "light-gold": "#FEDB4B",
  "dark-purple": "#452650",
  "dark-violet": "#362D59",
  "dark-pink": "#C83852",
  cream: "#FFFDD0",
};

export const DARK_COLORS = [
  "purple",
  "violet",
  "blurple",
  "pink",
  "dark-purple",
  "dark-violet",
  "dark-pink",
  "light-violet",
  "light-purple",
];
