import "./likebutton.css";
import { useState } from "react";
import { BASEURL } from "../constants.js";

function LikeButton({ quote_id, variant, setNumLikes, setIsLiked, isLiked }) {
  const [hovered, setHovered] = useState(false);

  const likeQuote = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify({
        quote_id: quote_id,
      }),
    };
    try {
      await fetch(BASEURL + "/likes", requestOptions);
    } catch (error) {
      console.error("Something went wrong while liking your quote:", error);
    }
  };

  const unlikeQuote = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify({
        quote_id: quote_id,
      }),
    };
    try {
      await fetch(BASEURL + "/likes", requestOptions);
    } catch (error) {
      console.error("Something went wrong while unliking your quote:", error);
    }
  };

  return (
    <span
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={(event) => {
        event.stopPropagation();
        setIsLiked();
        setNumLikes((numLikes) => {
          if (!isLiked) {
            return numLikes + 1;
          } else {
            return numLikes - 1;
          }
        });
        if (!isLiked) {
          likeQuote();
        } else {
          unlikeQuote();
        }
      }}
      className={`like-button ${hovered && "hovered"} ${variant}`}
    >
      {isLiked ? "♥" : "♡"}
    </span>
  );
}

export default LikeButton;
