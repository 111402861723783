import React, { useState, useEffect } from "react";

import variant1 from "../img/quoteboard-variant1.svg";
import variant2 from "../img/quoteboard-variant2.svg";
import variant3 from "../img/quoteboard-variant3.svg";

const images = [variant1, variant2, variant3];

function Header() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 300);

    return () => clearInterval(timer);
  }, []);

  return (
    <img
      className="header"
      alt="bruh"
      style={{ maxWidth: "65%", padding: "3vw" }}
      src={images[index]}
    />
  );
}

export default Header;
