import React, { useEffect, useState } from "react";
import "./Main.css";
import "../components/toast.css";
import { COLORS } from "../colors.js";
import Header from "../components/header.js";
import Quote from "../components/quote.js";
import UpIcon from "../img/up-icon.svg";
import PlusIcon from "../img/plus-icon.svg";
import SortDropdown from "../components/sort.js";
import { BASEURL } from "../constants.js";
import AddQuoteModal from "../components/addQuoteModal.js";
import Toast from "../components/toast.js";
import ExpandedQuote from "../components/expandedQuote.js";
import Pagination from "../components/pagination.js";

export const darkColors = ["dark-purple", "violet", "pink"];

function getColor({ id }) {
  const colors = Object.keys(COLORS);
  return colors[Math.floor(parseInt(id) % 15)];
}

const handleScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

function Main() {
  const [quotes, setQuotes] = useState([]);
  const [offset, setOffset] = useState(0);
  const [quoteCount, setQuoteCount] = useState(0);

  useEffect(() => {
    fetchData();
    getCount();
    fetchLikedPosts();
  }, [offset]);

  const fetchData = async () => {
    try {
      const response = await fetch(BASEURL + `/quotes/new?offset=${offset}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("authToken")}`,
        },
      });
      const result = await response.json();
      setQuotes(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchLikedPosts = async () => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("authToken")}`,
        },
      };
      const response = await fetch(BASEURL + `/likes`, requestOptions);
      const result = await response.json();
      setLikedQuotes(result["liked_quotes_ids"]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching liked posts:", error);
    }
  };

  const getCount = async () => {
    try {
      const response = await fetch(BASEURL + `/quotes/count`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("authToken")}`,
        },
      });
      const result = await response.json();
      setQuoteCount(parseInt(result));
    } catch (error) {
      console.error("Error fetching quote count:", error);
    }
  };

  const timePeriodMap = new Map([
    ["Top this week", "week"],
    ["Top this month", "month"],
    ["Top this year", "year"],
  ]);

  const fetchTopData = async (value) => {
    try {
      const response = await fetch(
        BASEURL + "/quotes/top?t=" + timePeriodMap.get(value),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("authToken")}`,
          },
        }
      );
      const result = await response.json();
      setQuotes(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataBySortMethod = async (value) => {
    setSortMethod(value);
    if (value === "New") {
      await fetchData();
    } else {
      await fetchTopData(value);
    }
  };

  const [sortMethod, setSortMethod] = useState("Newest");

  useEffect(() => {
    switch (sortMethod) {
      case "Top this week":
      case "Top this month":
      case "Top this year":
      case "New":
      default:
    }
  }, [sortMethod]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSuccess = () => {
    const x = document.getElementById("toast");
    x.className = "show";
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000); // do not change this value to be greater than 3000. don't ask why
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [quoteText, setQuoteText] = useState("");
  const [quoteSpeaker, setQuoteSpeaker] = useState("");
  const [quoteOpen, setQuoteOpen] = useState(false);
  const [quoteColor, setQuoteColor] = useState("");

  const [likedQuotes, setLikedQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleQuoteClose = () => {
    setQuoteOpen(false);
  };
  const handleQuoteOpen = (text, speaker, color) => {
    setQuoteOpen(true);
    setQuoteText(text);
    setQuoteSpeaker(speaker);
    setQuoteColor(color);
  };

  return !isLoading ? (
    <div className="main-layout">
      <Header />
      <div className="sort-search-taskbar">
        <SortDropdown setMethod={fetchDataBySortMethod} />
      </div>
      <div className="quotes">
        {quotes.length
          ? quotes.map((quote) => (
              <Quote
                key={quote.id}
                quote_id={quote.id}
                color={`${getColor({ id: quote.id })}`}
                message={quote.text || "Quote not found"}
                speaker={quote.speaker || "Speaker not found"}
                likes={quote.likes || 0}
                handleQuoteOpen={handleQuoteOpen}
                isAlreadyLiked={likedQuotes.includes(quote.id)}
              />
            ))
          : null}
      </div>
      <div className="sticky">
        <button className="icon-button" onClick={handleOpen}>
          <span className="tooltip-text">ADD</span>
          <img src={PlusIcon} alt="add quote" />
        </button>
        <button className="icon-button" onClick={handleScrollToTop}>
          <span className="tooltip-text">Scroll</span>
          <img src={UpIcon} alt="scroll to top" />
        </button>
      </div>
      {Boolean(quotes.length) && (
        <Pagination
          offset={offset}
          setOffset={setOffset}
          handleScroll={handleScrollToTop}
          count={quoteCount}
        />
      )}
      <AddQuoteModal
        isOpen={open}
        onClose={handleClose}
        onSuccess={handleSuccess}
      />
      <Toast />
      <ExpandedQuote
        message={quoteText}
        speaker={quoteSpeaker}
        color={quoteColor}
        isOpen={quoteOpen}
        onClose={handleQuoteClose}
      />
    </div>
  ) : (
    <p id="loading">loading</p>
  );
}

export default Main;
