import "./expandedQuote.css";
import "./quote.css";
import { useEffect } from "react";
import { COLORS, DARK_COLORS } from "../colors";

function ExpandedQuote({ message, speaker, color, isOpen, onClose }) {
  const hex = COLORS[color];
  const text_hex = DARK_COLORS.includes(color) ? "white" : "black";

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="darken-background"
      onClick={(event) => {
        if (event.target.className === "darken-background") {
          onClose();
        }
      }}
    >
      <span style={{ position: "relative" }}>
        <div
          style={{
            background: `${hex}`,
            borderTopColor: `${hex}`,
            color: `${text_hex}`,
          }}
          className="speech-bubble expanded"
        >
          <button
            className="close-button"
            onClick={onClose}
            style={{ color: `${text_hex}` }}
          >
            ×
          </button>
          <div>"{message}"</div>
          <span className="side-by-side">
            <div style={{ fontWeight: "bold" }}>{speaker}</div>
          </span>
        </div>
      </span>
    </div>
  );
}

export default ExpandedQuote;
