import "./login.css";
import Header from "../components/header";
import SentryLogo from "../img/sentry-logo.svg";

function Login() {
  const redirect = async () => {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/slack/signin"
    );
    const jsonObj = await response.json();
    localStorage.setItem("stateJWT", jsonObj.stateJWT);
    window.location.href = jsonObj.url;
  };
  return (
    <div className="page-body">
      <img className="logo" alt="sentry logo" src={SentryLogo} />
      <Header />
      <button className="sign-in" onClick={redirect}>
        Sign in with Slack
      </button>
    </div>
  );
}

export default Login;
