import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("authToken") != null
  );
  const authToken = searchParams.get("code");
  const state = searchParams.get("state");

  useEffect(() => {
    if (authToken && state) {
      const authenticate = async () => {
        try {
          console.log(authToken, state);
          const response = await fetch(
            `${
              process.env.REACT_APP_BACKEND_URL
            }/slack/user_oauth_redirect?code=${authToken}&state=${state}&stateJWT=${localStorage.getItem(
              "stateJWT"
            )}`
          );
          const result = await response.json();
          console.log(result);
          const validationResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/slack/validate`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(result),
            }
          );
          const data = await validationResponse.json();
          const token = data.token;

          localStorage.setItem("authToken", token);
          setIsAuthenticated(true);

          // Clean up the URL parameters
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.delete("code");
          setSearchParams(newSearchParams);
          window.history.replaceState(null, "", window.location.pathname);
        } catch (error) {
          console.error("Error during Slack authentication:", error);
          setIsAuthenticated(false);
        }
      };

      authenticate();
    }
  }, [authToken, state, searchParams, setSearchParams]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
