import { useEffect } from "react";
import "./addQuoteModal.css";
import { useForm } from "react-hook-form";

import { BASEURL } from "../constants.js";

function AddQuoteModal({ isOpen, onClose, onSuccess }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [onClose]);

  if (!isOpen) {
    return null;
  }

  const onSubmit = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify({
        text: data.quote,
        speaker: data.speaker,
      }),
    };
    try {
      fetch(BASEURL + "/quotes/post", requestOptions).then(() => {
        onClose();
        onSuccess();
      });
    } catch (error) {
      console.error("Something went wrong while posting your quote:", error);
    }
  };

  const check = (event) => {
    const stringLength = event.target.value.length;
    const remainingChars = 256 - stringLength;
    document.getElementById(
      "charsRemaining"
    ).innerText = `Remaining characters: ${remainingChars}`;
  };

  return (
    <div
      className="darken-background"
      onClick={(event) => {
        if (event.target.className === "darken-background") {
          onClose();
        }
      }}
    >
      <div className="modal">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h1>Add a Quote</h1>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="input-wrapper">
            <textarea
              className="quote-input input"
              {...register("quote", { required: true, maxLength: 256 })}
              placeholder="Quote"
              maxLength={256}
              onKeyUp={(event) => check(event)}
            />

            {errors.quote?.type === "required" && (
              <span className="error">* Quote is required</span>
            )}
            <span id="charsRemaining" hidden={errors.quote?.type}>
              Remaining characters: 256
            </span>
          </div>
          <div className="input-wrapper">
            <input
              className="input"
              {...register("speaker", { required: true, maxLength: 80 })}
              placeholder="Speaker"
            />
            {errors.speaker?.type === "required" && (
              <span className="error">* Speaker is required</span>
            )}{" "}
            {errors.speaker?.type === "maxLength" && (
              <span className="error">Speaker cannot exceed 80 characters</span>
            )}
          </div>
          <div className="submit-wrapper">
            <input className="submit" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddQuoteModal;
