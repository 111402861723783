import "./quote.css";
import { COLORS, DARK_COLORS } from "../colors";
import LikeButton from "./likebutton";
import { useState } from "react";

function truncateMessage(message) {
  return message.length > 90 ? message.substring(0, 90) + "..." : message;
}

function Quote({
  quote_id,
  message,
  speaker,
  color,
  likes,
  isAlreadyLiked,
  handleQuoteOpen,
}) {
  const hex = COLORS[color];
  const variant = DARK_COLORS.includes(color) ? "light" : "dark";
  const [numLikes, setNumLikes] = useState(likes);

  const [isLiked, setIsLiked] = useState(isAlreadyLiked);

  const toggleLike = () => {
    setIsLiked(!isLiked);
  };

  return (
    <span style={{ position: "relative" }}>
      <div
        style={{
          background: `${hex}`,
          borderTopColor: `${hex}`,
          color: `${DARK_COLORS.includes(color) ? "white" : "black"}`,
        }}
        className="speech-bubble"
        onClick={() => {
          handleQuoteOpen(message, speaker, color);
        }}
      >
        <div>"{truncateMessage(message)}"</div>
        <span className="side-by-side">
          <div style={{ fontWeight: "bold" }}>{speaker}</div>
          <div>
            {numLikes}{" "}
            <LikeButton
              quote_id={quote_id}
              variant={variant}
              setNumLikes={setNumLikes}
              setIsLiked={toggleLike}
              isLiked={isLiked}
            />
          </div>
        </span>
      </div>
    </span>
  );
}

export default Quote;
