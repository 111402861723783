import { React } from "react";
import "./sort.css";

const SortDropdown = ({ setMethod }) => {
  const sortOptions = ["New", "Top this week", "Top this month", "Top this year"];

  return (
    <div>
      <div className="dropdown-wrapper">
        <strong> Sort by: </strong>
        <select
          name="sort"
          onChange={(e) => {
            setMethod(e.target.value);
          }}
          className="dropdown"
        >
          {sortOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SortDropdown;
