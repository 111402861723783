import "./pagination.css";
import Left from "../img/left-arrow.png";
import Right from "../img/right-arrow.png";

function Pagination({ offset, setOffset, count }) {
  const leftDisabled = offset === 0;
  const rightDisabled = offset + 30 >= count;

  return (
    <div className="pagination">
      <button
        disabled={leftDisabled}
        className={leftDisabled ? "disabled" : "enabled"}
        onClick={() => {
          setOffset((offset) => offset - 30);
          window.scrollTo(0, 0); // this scroll won't work if i put in smooth idk why don't come for me
        }}
      >
        <img src={Left} alt="left" />
      </button>
      <button
        disabled={rightDisabled}
        className={rightDisabled ? "disabled" : "enabled"}
        onClick={() => {
          setOffset((offset) => offset + 30);
          window.scrollTo(0, 0); // this scroll won't work if i put in smooth idk why don't come for me
        }}
      >
        <img src={Right} alt="right" />
      </button>
    </div>
  );
}
export default Pagination;
